import * as React from 'react';
import { AppBar, Box, Container, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

import { blueGrey } from '@mui/material/colors';
import { borderBottomColor } from '@mui/system';

const appBarStyle = {
  boxShadow: 'none',
  color: blueGrey[900],
  pt: 1,
  pb: 1,
};

export default function AppHeader() {
  return (
    <AppBar position="static" color="transparent" sx={appBarStyle}>
      <Container maxWidth="lg">
        <Toolbar>
          <BubbleChartIcon />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 600 }}>sldstats</Typography>
          <Button color="inherit" href="mailto:james@rayers.com">Contact</Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}