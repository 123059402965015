import React, { useState } from 'react';
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow, TableFooter, TablePagination, Skeleton, Typography } from '@mui/material';

const style = {
  p: 0,
  width: '100%',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
};

export default function ResultsTable({ rows, loading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Stack spacing={1}>
        {loading ? (
        <Typography variant="h2" sx={{ pb: 1 }}>Searching...</Typography>
        ) : (
        <Typography variant="h2" sx={{ pb: 1 }}>{rows.length} Results</Typography>
        )}
        <TableContainer sx={style}>
            <Table>
                <TableBody>
                {loading ? (
                    Array.from(new Array(10)).map((_, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" padding="none" sx={{ px: 2, py: 1 }}>
                            <Skeleton variant="text" width="66%" />
                        </TableCell>
                    </TableRow>
                    ))
                ) : (
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" padding="none" sx={{ px: 2, py: 1 }}>
                            <Typography>{row}</Typography>
                        </TableCell>
                    </TableRow>
                    ))
                )}
                </TableBody>
            </Table>
        </TableContainer>
        {!loading && (
        <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
        />
        )}
    </Stack>
  );
}
