export async function getSerp(keyword, resultsLimit) {

    let url = `https://api.sldstats.com/serp/?sld=${keyword}`;
    
    try {

        let response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        
        let data = await response.json();

        console.log(data);

        return data;

    } catch (error) {

        throw new Error(`${error}`);

    }

}