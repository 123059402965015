import './App.css';

import { useState } from "react";
import { getSerp } from './services/serp';

import Results from './components/Results';
import ResultsTable from './components/ResultsTable';
import AppHeader from './components/AppHeader';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EastIcon from '@mui/icons-material/East';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({

  typography: {
    fontFamily: "'Inter Variable', sans-serif",
    allVariants: {
      fontFeatureSettings: "'cv02', 'cv03', 'cv04', 'cv11'",
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
  },

});

export default function App() {

  const [sld, setSld] = useState('');
  const [results, setResults] = useState(false);
  const [resultsKey, setResultsKey] = useState(0);

  const [serpResultsList, setSerpResultsList] = useState(null);
  const [serpResultsCount, setSerpResultsCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [inputError, setInputError] = useState(false);
  const [inputErrorText, setinputErrorText] = useState(null);

  const resultsLimit = 5;

  const resetResults = () => {
    setResultsKey((prevKey) => prevKey + 1);
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    resetResults();

    if (inputError) {
      return;
    }

    setLoading(true);

    getSerp(sld, resultsLimit)
    .then((data) => {
      setSerpResultsList(data.results_list);
      setSerpResultsCount(data.results_count);
      setResults(true);
      setLoading(false);
    })
    .catch((error) => {
      setError(error);
      setLoading(false);
    });

  };

  const permittedCharacters = /^[a-zA-Z0-9-]+$/;

  const handleChange = (e) => {

    setSld(e.target.value);
    setInputError(false);
    setinputErrorText(null);

    if (!permittedCharacters.test(e.target.value)) {
      setInputError(true);
      setinputErrorText("No spaces or special characters allowed.")
    }
    
    if (!e.target.value) {
      setInputError(true);
      setinputErrorText("SLD is required.")
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <AppHeader></AppHeader>
      <Container component="main" maxWidth="sm" sx={{ p: 4 }}>
        <Stack spacing={4}>
          <Box component="form">
              <TextField
                fullWidth
                id="sld"
                placeholder="Enter SLD..."
                type="string"
                onChange={handleChange}
                error={inputError}
                helperText={inputErrorText}
                InputProps={{
                  endAdornment: <Button variant="contained" type="submit" disabled={inputError} onClick={handleSubmit} endIcon={<EastIcon />}>Search</Button>
                }}
              ></TextField>
          </Box>
          {results || loading ? (
          <Box>
            <ResultsTable key={resultsKey} rows={serpResultsList} loading={loading} />
          </Box>
          ) : null }
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
